import * as Sentry from '@sentry/react'
import axios from 'axios'

const logError = (data: any) => {
  console.log(data)
  if (axios.isAxiosError(data)) {
    Sentry.captureException({ ...data, response: data.response, requestData: data.config.data })
    return
  }
  Sentry.captureException(data)
}

export const logger = {
  logError,
}
