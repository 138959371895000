import { differenceInYears, format, parse } from 'date-fns'

const formatToLocale = (dateToFormat: string | Date) => {
  const date: Date =
    typeof dateToFormat === 'string' ? parse(dateToFormat, 'dd-MM-yyyy', new Date()) : dateToFormat
  return format(date, 'PPP')
}

const formateToLocaleShort = (dateToFormat: string | Date) => {
  const date: Date =
    typeof dateToFormat === 'string' ? parse(dateToFormat, 'dd-MM-yyyy', new Date()) : dateToFormat
  return format(date, 'P').replaceAll('/', '-')
}

const parseToDate = (date: string, format: string = 'dd-MM-yyyy') => parse(date, format, new Date())

const formatToCardExpMonthYear = (date: Date) => {
  return format(date, 'MM/yy')
}

const isDateValid = (date: Date | null) => {
  return !!date && date instanceof Date && date.toString() !== 'Invalid Date'
}

const calculateAge = ({ bornDate }: { bornDate: Date }) => {
  return differenceInYears(new Date(), bornDate)
}

const toServerString = (date: Date) => format(date, 'dd-MM-yyyy')

const getYearsOld = (date: Date) => differenceInYears(new Date(), date)

export const dateFormatter = {
  formatToLocale,
  parseToDate,
  formateToLocaleShort,
  formatToCardExpMonthYear,
  isDateValid,
  calculateAge,
  toServerString,
  getYearsOld,
}
