export const APP_ROUTES = {
  INITIAL: '/',
  CHECKIN: '/checkin',
  CHECKIN_REGISTER: '/checkin/register',
  CHECKIN_FILL: '/checkin/:id/fill',
  PAYMENT: '/payment',
  PAYMENT_CHANNEL_VALIDATION: '/payment/channel-validation',
  PAYMENT_ADVANCE: '/payment/advance',
  PAYMENT_BAIL: '/payment/deposit',
  PAYMENT_TOTAL: '/payment/total',
  PAYMENT_PARTIAL: '/payment/partial',
}

export const getRoute = (to: string, values: { [key: string]: string }) => {
  let filledPath = to
  Object.entries(values).forEach(([key, value]) => {
    filledPath = filledPath.replace(`:${key}`, value)
  })
  return filledPath
}
