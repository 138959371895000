import React from 'react'
import { Box, FormControl, MenuItem, Select, capitalize } from '@mui/material'
import { Languages, useLanguageContext } from '@/contexts/LanguageContext'

// export const StyledSelect = styled(Select, {
//   shouldForwardProp: (prop) => prop !== 'page',
// })<
//   SelectProps & {
//     page: string
//   }
// >(({ page }) => ({
//   height: '36.5px',
//   width: '101.5px',
//   color: `${page === CheckinPages.Checkin ? 'white' : '#1976d2'}`,
//   '& .MuiSvgIcon-root': {
//     color: `${page === CheckinPages.Checkin ? 'white' : '#1976d2'}`,
//   },
//   // outline: "none"
//   '.MuiOutlinedInput-notchedOutline': {
//     border: 'none',
//   },
// }))

const LanguageSelector = ({ color = 'black' }: { color?: 'black' | 'white' }) => {
  const { changeLanguage, language } = useLanguageContext()

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '2rem',
        right: '2rem',
        zIndex: '999',
        width: '100px',
      }}
    >
      <FormControl
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '36.5px',
            width: '101.5px',
            ...(color === 'black' && {
              border: '1px solid black',
              '& *': {
                color: 'black',
              },
            }),
            ...(color === 'white' && {
              border: '1px solid white',
              '& *': {
                color: 'white',
              },
            }),
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
        }}
        fullWidth
      >
        <Select
          value={language}
          onChange={(event) => {
            changeLanguage(event.target.value as Languages)
          }}
          // page={CheckinPages.Checkin}
        >
          {Object.values(Languages).map((language) => {
            return (
              <MenuItem key={language} value={language}>
                {capitalize(language)}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  )
}

export default LanguageSelector
